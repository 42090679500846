<script>
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config";


export default {
  page: {
    title: "Automation",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout
  },
  data() {
    return {
      pagetitle: "Automation",
    };
  },
};
</script>

<template>
  <Layout :pagetitle="pagetitle">
  
  </Layout>
</template>
